
import PDEIcon from '@/components/pde/PDEIcon.vue'
import PDEHeading from '@/components/pde/PDEHeading.vue'

export default {
  name: 'UIAccordion',
  components: {
    PDEHeading,
    PDEIcon,
  },
  props: {
    title: {
      type: String,
      default: '',
    },

    spacer: {
      type: Boolean,
      default: false,
    },

    open: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    active: {
      type: Boolean,
      default: false,
    },

    skeletonTitle: {
      type: [Boolean, Object],
      default: false,
    },
  },
  emits: ['accordionClick'],

  data(props) {
    return {
      isOpen: props.open,
    }
  },

  computed: {
    getSkeletonTitleStyle() {
      let skeletonTitleWidth

      if (this.skeletonTitle) {
        skeletonTitleWidth = '50%'

        if (
          typeof this.skeletonTitle === 'object' &&
          this.skeletonTitle.width
        ) {
          if (typeof this.skeletonTitle.width === 'string') {
            skeletonTitleWidth = this.skeletonTitle.width
          }

          if (typeof this.skeletonTitle.width === 'number') {
            skeletonTitleWidth = `${this.skeletonTitle.width}px`
          }
        }
      } else {
        skeletonTitleWidth = '0px'
      }

      return {
        width: skeletonTitleWidth,
      }
    },
  },
  methods: {
    accClick() {
      this.isOpen = !this.isOpen
      const action = this.isOpen ? 'open' : 'closed'

      this.$emit('accordionClick', action)
    },
  },
}
