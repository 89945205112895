
import PDEHeading from '@/components/pde/PDEHeading.vue'

export default {
  name: 'UIAccordion2Entry',
  components: { PDEHeading },
  props: {
    title: { type: String, default: '' },
    link: { type: String, default: '' },
    divider: { type: Boolean, default: false },
  },
}
